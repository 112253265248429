import React from "react"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Select from 'react-select'

const bigInt = require("big-integer")

class SystemPicker extends React.Component {
  options = [
    { label: '2er-System [01]', value: 2 },
    { label: '8er-System [01234567]', value: 8 },
    { label: '16er-System [0123456789ABCDEF]', value: 16 },
    { label: '36er-System [0123456789ABC...XYZ]', value: 36 },
  ]

  state = {
    selected: this.options[this.props.selectedIndex || 0],
  }

  handleChange(updateValue) {
    this.setState({
        selected: updateValue,
    });
  }

  get name() {
      return this.props.name;
  }

  get value() {
    return this.state.selected.value;
  }

  render() {
    return (
      <Select
        options={this.options}
        onChange={value => this.handleChange(value)}
        defaultValue={this.state.selected}
      />
    )
  }
}

export default class Page extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mulNumber: '1101',
      mulSystem: '2',
      divNumber: '35',
      divSystem: '2'
    }

    this.handleClickMul = this.handleClickMul.bind(this)
  }

  componentDidMount() {
    this.transferRefsToState();
  }

  handleClickMul(event) {
    event.preventDefault()
    this.transferRefsToState();
  }

  transferRefsToState() {
    Object.values(this.refs).forEach(field => {
        this.setState({
          [field.name]: field.value,
        });
      });
  }

  createTable = (alphabet, number) => {

    let row = []
    let sum = bigInt(0)
    const numberLength = number.length
    const alphabetLength = alphabet.length

    for (let y = 1; y<=numberLength; y++) {

      row[y] = new Array(4)
      row[y][0] = y - 1
      let digitValue = bigInt(alphabet.length).pow(bigInt(y - 1))
      row[y][1] = digitValue.toString()
      let digit = number.charAt(number.length - y)
      let digitNumber = alphabet.indexOf(digit)
      row[y][2] = digit

      if (digitNumber > 9) {
        row[y][2] = digit + " (" + digitNumber + ")"
      } else {
        row[y][2] = digit
      }

      let decimalValue = digitValue.multiply(digitNumber)
      row[y][3] = decimalValue.toString()

      sum = sum.add(decimalValue)
    }

    let table = []

    table.push(<thead><tr><th>Stelle<br />von rechts</th><th>Stellenwert</th><th>Ziffer</th><th>Dezimalwert</th></tr></thead>)

    for (let y = 1; y<=numberLength; y++) {
      let children = []

      //children.push(<td>{`Column <sup>${j + 1}</sup>`}</td>)
      children.push(<td>{y}</td>)
      children.push(<td>{alphabetLength}<sup>{row[y][0]}</sup>={row[y][1]}</td>)
      children.push(<td>{row[y][2]}</td>)
      children.push(<td>{row[y][3]}</td>)

      table.push(<tr>{children}</tr>)
    }

    table.push(<tr><th>Summe</th><th> </th><th> </th><th>{sum.toString()}</th></tr>)


    return table
  }


  renderDivMul() {
    let number = this.state.mulNumber
    let system = this.state.mulSystem

    let systemNumber

    switch (system) {
      case 2:
        systemNumber = 0
        break
      case 8:
        systemNumber = 1
        break
      case 16:
        systemNumber = 2
        break
      case 36:
        systemNumber = 3
        break
      default:
        systemNumber = 0
    }

    const alphabets = ['01',
      '01234567',
      '0123456789ABCDEF',
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

    const alphabetsRegEx = ['0-1',
      '0-7',
      '0-9A-F',
      '0-9A-Z'];

    const alphabet = alphabets[systemNumber];
    const alphabetRegEx = alphabetsRegEx[systemNumber];

    const strRegEx = "^([" + alphabetRegEx +  "])+$";
    let regEx = new RegExp(strRegEx);

    if (!(regEx.test(number))) {
      return <>Die Zahl {number} enthält Ziffern, die nicht erlaubt sind.</>
    } else {
      return <>Die Zahl {number}<sub>{system}</sub> soll ins Dezimalsystem
        (10-er System) umgewandelt werden. Die Berechnung erfolgt in einer Tabelle:
        <br /><br />

        <table>
          {this.createTable(alphabet, number)}
        </table>
      </>
    }
  }

  render() {
    const divStyle = {
      borderColor: "#222222",
      marginLeft: "0px",
      marginTop: "0px",
      borderWidth: "1px",
      borderStyle: "solid",
      padding: "4px",
    }

    return (
      <Layout title="Stellenwertsysteme">
        <SEO title="Stellenwertsysteme"/>
        <h2>Dezimalsystem (10er-System)</h2>
        Normalerweise rechnen wir im Dezimalsystem und verwenden dabei die zehn
        arabischen Ziffern 0, 1, 2, 3, 4, 5, 6, 7, 8 und 9. Der Wert einer
        Ziffer in einer Zahl hängt von ihrer Stelle ab. Im Dezimalsystem hat die
        <ul>
          <li>
            1. Stelle von rechts den Stellenwert 10<sup>0</sup> = 1 (Einer).
          </li>
          <li>
            2. Stelle von rechts den Stellenwert 10<sup>1</sup> = 10 (Zehner).
          </li>
          <li>
            3. Stelle von rechts den Stellenwert 10<sup>2</sup> = 100
            (Hunderter).
          </li>
          <li>
            4. Stelle von rechts den Stellenwert 10<sup>3</sup> = 1000
            (Tausender).
          </li>
          <li>...</li>
          <li>
            n. Stelle von rechts den Stellenwert 10<sup>n-1</sup>.
          </li>
        </ul>
        Die Basis 10 ergibt sich durch die Anzahl der verwendeten Ziffern.
        <br/>
        <br/>
        <u>Beispiel:</u>
        <br/>
        Die Dezimalzahl 234 kann auch wie folgt geschrieben werden: <br/>2 * 10
        <sup>2</sup> + 3 * 10<sup>1</sup> + 4 * 10<sup>0</sup> = 2 * 100 + 3 *
        10 + 4 * 1 = 234
        <br/>
        <br/>
        <h2>Binärsystem (2er-System)</h2>
        Das System zur Basis 2 (Binärsystem) kommt nur mit den beiden Ziffern 0
        und 1 aus.
        <br/>
        Der Wert einer Ziffer in einer Zahl hängt von ihrer Stelle ab. Im
        Binärsystem hat die
        <ul>
          <li>
            1. Stelle von rechts den Stellenwert 2<sup>0</sup> = 1.
          </li>
          <li>
            2. Stelle von rechts den Stellenwert 2<sup>1</sup> = 2.
          </li>
          <li>
            3. Stelle von rechts den Stellenwert 2<sup>2</sup> = 4.
          </li>
          <li>
            4. Stelle von rechts den Stellenwert 2<sup>3</sup> = 8.
          </li>
          <li>...</li>
          <li>
            n. Stelle von rechts den Stellenwert 2<sup>n-1</sup>.
          </li>
        </ul>
        Die Basis 2 ergibt sich durch die Anzahl der verwendeten Ziffern.
        <br/>
        <br/>
        <u>Beispiel einer Umwandlung nach der Multiplikationsmethode:</u>
        <br/>
        Die Binärzahl 10011111<sub>(2)</sub> entspricht der Dezimalzahl
        <br/>1 * 2<sup>7</sup> + 0 * 2<sup>6</sup> + 0 * 2<sup>5</sup> + 1 * 2
        <sup>4</sup> + 1 * 2<sup>3</sup> + 1 * 2<sup>2</sup> + 1 * 2<sup>1</sup>{" "}
        + 1 * 2<sup>0</sup> = 159
        <br/>
        Die tiefergestellte 2 in Klammern bedeutet, dass die vorgestellte Zahl
        eine Zahl im 2er-System ist.
        <br/>
        <br/>
        Jede einzelne Stelle nennt sich im Binärsystem Bit (<b>B</b>inary Dig
        <b>it</b>).
        <br/>
        Acht Bits hintereinander aufgeschrieben sind ein Byte. Die Binärzahl
        10011111<sub>(2)</sub> ist also ein Byte.
        <br/>
        <br/>
        <h2>Hexadezimalsystem (16er-System)</h2>
        Das System zur Basis 16 (Hexadezimalsystem) benötigt 16 Ziffern:
        <br/>
        <br/>
        <table>
          <tbody>
            <tr>
                <th>Dezimal</th>
                <td>0</td>
                <td>1</td>
                <td>2</td>
                <td>...</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
                <td>14</td>
                <td>15</td>
            </tr>
            <tr>
                <th>Hexadezimal</th>
                <td>0</td>
                <td>1</td>
                <td>2</td>
                <td>...</td>
                <td>9</td>
                <td>A</td>
                <td>B</td>
                <td>C</td>
                <td>D</td>
                <td>E</td>
                <td>F</td>
            </tr>
          </tbody>
        </table>
        <br/>
        Der Wert einer Ziffer in einer Zahl hängt von ihrer Stelle ab. Im
        Hexadezimalsystem hat die
        <ul>
          <li>
            1. Stelle von rechts den Stellenwert 16<sup>0</sup> = 1.
          </li>
          <li>
            2. Stelle von rechts den Stellenwert 16<sup>1</sup> = 16.
          </li>
          <li>
            3. Stelle von rechts den Stellenwert 16<sup>2</sup> = 256.
          </li>
          <li>
            4. Stelle von rechts den Stellenwert 16<sup>3</sup> = 4096.
          </li>
          <li>...</li>
          <li>
            n. Stelle von rechts den Stellenwert 16<sup>n-1</sup>.
          </li>
        </ul>
        Die Basis 16 ergibt sich durch die Anzahl der verwendeten Ziffern.
        <br/>
        <br/>
        <u>Beispiel einer Umwandlung nach der Multiplikationsmethode:</u>
        <br/>
        Die Hexadezimalzahl 3FA<sub>(16)</sub> entspricht der Dezimalzahl
        <br/>3 * 16<sup>2</sup> + 15 * 16<sup>1</sup> + 10 * 16<sup>0</sup> =
        1018
        <br/>
        <br/>
        Die tiefergestellte 16 in Klammern bedeutet, dass die vorgestellte Zahl
        eine Zahl im 16er-System ist.
        <br/>
        <br/>
        <h2>Multiplikationsmethode</h2>
        Für die Umrechnung von einem beliebigen Zahlensystem in das
        Dezimalsystem brauchst du die sogenannte Multiplikationsmethode,
        die oben schon für jedes Zahlensystem erläutert wurde.
        <br/>
        <br/>
        Zahl:&nbsp;
        <input
          aria-label="mulNumber"
          name="mulNumber"
          ref="mulNumber"
          defaultValue={this.state.mulNumber}
        />
        <br/>
        Zahlensystem:&nbsp;<SystemPicker ref="mulSystem" name="mulSystem" selectedIndex={0}/>
        <br/>
        <button
          className="btn btn-primary"
          onClick={this.handleClickMul}
        >
          Wandele die Zahl vom gewählten Zahlensystem ins 10er-System um...
        </button>
        <br/><br/>
        <div style={divStyle}>{this.renderDivMul()}</div>
        <br/>
        <br/>

        <b>Aufgabe 1)</b><br/>
        Wandle folgende Binärzahlen auf einem Zettel in Dezimalzahlen um und kontrolliere die Umwandlung.<br/>
        a) 01100110, b) 10011, c) 10, d) 11010111001<br/><br/>

        <b>Aufgabe 2)</b><br/>
        Wandle folgende Hexadezimalzahlen auf einem Zettel in Dezimalzahlen um und kontrolliere
        dann die Umwandlung.<br/>
        a) 4C2A, b) FF, c) ABBA, d) 1234<br/><br/>

        <b>Aufgabe 3)</b><br/>
        Wandle folgende Oktalzahlen auf einem Zettel in Dezimalzahlen um und kontrolliere
        dann die Umwandlung.<br/>
        a) 123, b) 77, c) 7012, d) 312<br/><br/>


        <h2>Divisionsmethode</h2>

        Für die Umrechnung vom Dezimalsystem in ein beliebiges Zahlensystem brauchst du die
        sogenannte Divisionsmethode, die die <a href="../division-mit-rest/"
           target="_blank">Modulo-Operation
        </a> verwendet.<br/><br/>

        Das Vorgehen wird in diesem Video vorgeführt:<br/>
        <iframe
          title="video" width="420" height="315"
          src="https://www.youtube.com/embed/m9KE4l0mC_0?rel=0"
          frameBorder="0">&nspb;
        </iframe>
        <br/>

        <br/>

        <b>Aufgabe 4)</b><br/>
        Wandle folgende Dezimalzahlen auf einem Zettel in Binär-, Oktal- und Hexadezimalzahlen um
        und kontrolliere die Umwandlung.<br/>
        a) 255, b) 123, c) 15, d) 1110, e) 9353<br/><br/>

        <b>Aufgabe 5)</b><br/>
        Wieso kann mit dem 36er-System aus jedem Text eindeutig genau eine Zahl ermittelt werden?

      </Layout>
    )
  }
}
